<template>
  <div class="d-flex justify-content-center">
    <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{$t('creator.addInformation')}}</h1>
        <p>{{$t('creator.text_addInformation')}}</p>
      </b-col>

      <b-col class="col-12 d-flex justify-content-center mt-3">
        <b-form-group class="w-100">
          <h5>{{domain}}/@{{vanity_name}}</h5>
          <b-input-group prepend="@" :class="stateVanity() === null ? '' : stateVanity() ? 'is-valid' : 'is-invalid'">
            <b-form-input 
              v-model="vanity_name" 
              @keyup.enter="vanity_name.length > 0 ? save() : ''" 
              @keypress="valite_input"
              @input="vanity_name = vanity_name.toLowerCase()" 
              :state="stateVanity()"
            ></b-form-input>
          </b-input-group>
          <small v-if="vanity_name.length === 0" class="text-danger">{{$t('creator.vanityRequitred')}}</small>
          <small class="d-block text-danger" v-if="is_vanity_in_use && (user.meta_info && user.meta_info.vanity_name) && user.meta_info.vanity_name !== vanity_name">{{$t('vanity_in_use')}}</small>
        </b-form-group>
      </b-col>
      <b-col class="col-12 d-flex justify-content-center">
        <b-button :disabled="!vanity_name || is_vanity_in_use" variant="blue-button-next-step" class="blue-button-next-step my-2" @click="save()"> {{$t('creator.buttonSave')}}</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BInputGroup,
  BFormInput
} from 'bootstrap-vue';
import others from '@/services/others'
import abilities from '@/libs/acl/abilities';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import config from '@/services/config';
import { setPermissions, setuserData } from '@/libs/utils/user'

export default {
  name: 'PublicProfileBasicInfo',
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BInputGroup,
    BFormInput
  },
  data() {
    return {
      setPermissions,
      setuserData,
      vanity_name: '',
      domain: window.origin,
      cancel_ws: null,
      axios: null,
      is_vanity_in_use: true,
      user: null
    }
  },
  computed: {
    
  },
  created() {
    this.getProfile()
  },
  async mounted() {
    this.axios = (await import('axios')).default
  },

  methods: {
    stateVanity() {
      if (this.user?.meta_info?.vanity_name) {
        if (this.user.meta_info.vanity_name === this.vanity_name) return null
        else return !this.is_vanity_in_use;
      } else {
        return !this.is_vanity_in_use;
      }
    },
    valite_input() {
      const regex = /^[A-Za-z0-9._]$/;
      const char = String.fromCharCode(event.keyCode);

      if (!regex.test(char)) {
        event.preventDefault();
      }
    },
    getProfile () {
      others.getProfile().then(response => {
        this.user = response.user

        if (this.user.meta_info && this.user.meta_info.vanity_name) this.vanity_name = this.user.meta_info.vanity_name
      })
    },
    save() {
      const data = {
        vanity_name: this.vanity_name,
      }

      others.updateProfile(data).then(response => {
        if (response.status) {
          const error = response.data.response
          if (error.code === 'accounts.DuplicatedVanityName') {
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                position: 'top-right',
                props: {
                  title: this.$t('campaigns.error'),
                  icon: 'BellIcon',
                  text: error.message,
                  variant: 'danger'
                }
              })
            })
          }
        } else {
          setPermissions(response.user);
          this.readAbility = abilities.getAbilitie(response.user)
          setuserData(response, this.readAbility)

          this.$root.$emit('quit_navbar_white');
          this.$router.push({name: 'my_work', params: {section: 'networks'}});
        }
      })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    isVanityInUse(vanity_name_input) {
      const CancelToken = this.axios.CancelToken;
      if (this.cancel_ws) this.cancel_ws()
      const self = this;
      this.axios({
        method: 'post',
        url: `${config.urlCore}/api/v3/profile/validate/vanity/`,
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        data: {
          vanity_name: vanity_name_input
        },
        cancelToken: new CancelToken(function executor(c) {
          self.cancel_ws = c;
        })
      }).then((response) => {
        this.is_vanity_in_use = response.data.response.ready_vanity_name
      })
    }
  },
  watch: {
    vanity_name(val) {
      if (val.length > 0) this.isVanityInUse(val);
      else this.is_vanity_in_use = true;
    }
  }
}
</script>
<style>
</style>