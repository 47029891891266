<template>
  <div class="d-flex">
    <v-select 
      :options="options" 
      label="title" 
      id="select-input-tel-brandme" 
      v-model="country" 
    >
      <template #selected-option="data">
        <div>
          <b-img :src="getFlag(data)" class="image-selected"></b-img>
        </div>
      </template>
      <template v-slot:option="option">
        <div class="option-submenu">
          <b-img :src="getFlag(option)" class="image"></b-img>
          {{ option.title }} (+{{option.code}})
        </div>
      </template>
    </v-select>
    <b-form-input 
      placeholder="number" 
      id="input-tel-brandme" 
      v-model="number"
      ref="input_tel_brandme"
      :state="telephoneState()"
    ></b-form-input>
  </div>
</template>

<script>
import {
  BImg,
  BFormInput,
} from 'bootstrap-vue';
import vSelect from 'vue-select'
import countries from '@/libs/countries-dial.json'


export default {
  name: 'InputTelBrandme',
  components: {
    BImg,
    BFormInput,
    vSelect,
  },
  props: {
    country_dad: {
      type: String,
      default: 'MX',
    },
    number_dad: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      options: countries,
      country: countries.find((i) => i.iso === this.country_dad),
      number: '',
    }
  },
  created() {
    this.getNumber();
  },
  methods: {
    getFlag(option) {
      let flag = '';
      try {
        flag = require(`@/assets/images/countries/${option.iso.toLowerCase()}.svg`)
      } catch (error) {
        flag = require("@/assets/images/dashboard/imgDefault.png")
      }
      return flag
    },
    getNumber() {
      this.number = `+${this.country.code} ${this.number_dad}`
    },
    telephoneState() {
      const number_format = this.number.replace(/\s+/g, '')
      const length_code_country = this.country.code.length;
      if ((number_format[0] !== '+') || this.country.code !== number_format.substring(1, length_code_country + 1)) return false;
      const number_without_code = number_format.substring(length_code_country + 1, number_format.length);
      if (!this.areNumbersFormat(number_without_code)) return false
      const only_numbers = number_without_code.match(/\d+/g).join("");
      if (only_numbers.length > 13 || only_numbers.length < 8) return false
      return true
    },
    areNumbersFormat(number) {
      return /^[()\-\s0-9]+$/.test(number);
    }
  },
  watch: {
    country(val) {
      this.number = `+${val.code} `
      this.$refs.input_tel_brandme.focus();
      this.$emit('number', {country: val.iso, number: this.number, state: this.telephoneState()});
    },
    number(new_val) {
      const number_splitted = new_val.split(`+${this.country.code}`)[1] ? new_val.split(`+${this.country.code}`)[1] : new_val
      this.$emit('number',{country: this.country.iso, number: number_splitted, state: this.telephoneState()});
    }
  }
}
</script>
<style>
#select-input-tel-brandme .vs__dropdown-menu {
  width: 500px !important;
  z-index: 100 !important;
  max-height: 500px !important; 
}
#select-input-tel-brandme .vs__dropdown-option {
  height: 48px;
  padding: 0;
}
#select-input-tel-brandme, #input-tel-brandme {

}
#select-input-tel-brandme .vs__clear {
  display: none;
}
#select-input-tel-brandme .vs__search {
  opacity: 0;
  position: absolute;
}
#select-input-tel-brandme .vs__dropdown-toggle {
  height: 100%;
  min-width: 70px;
  border: 0;
  box-shadow: none;
}
#select-input-tel-brandme .vs__selected {
  transform: none !important;
  opacity: 1 !important;
  position: relative !important;
  display: inline-block;
}
.vs--single {
  border: 1px solid transparent;
}
.vs--open {
  border: 1px solid #7367f0;
  background-color: padding-box;
  border-radius: 0.357rem;
  box-shadow: 1px 0px 10px #e6e6e6;
}
#select-input-tel-brandme .vs__dropdown-option--highlight {
  background: #6c61e918 !important;
}
#select-input-tel-brandme .vs__dropdown-option--selected {
  background:  #7367f0 !important;
  color: white !important;
}
#select-input-tel-brandme .vs__open-indicator {
  fill: transparent;
}

</style>

<style scoped>
.image {
  width: 25px;
  height: 25px;
  margin-right: 1em;
}
.image-selected {
  width: 25px;
  height: 25px;
}
.option-submenu {
  height: 48px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: 'avenir-medium';
  padding: 0 1em;
}
</style>