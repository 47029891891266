<template>
  <div class="d-flex justify-content-center">
    <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{$t('creator.basicInformation')}}</h1>
        <p>{{$t('creator.text_buying_or_Selling')}}</p>
      </b-col>

      <!-- Location -->
      <validation-observer ref="save" #default="{invalid}"> 
      <b-form>
      <b-col class="col-12 mt-3">
        <h3 class="text-left">{{$t('creator.titleLocation')}}</h3>
        <validation-provider
          :name="$t('telephone')"
          :vid="$t('telephone')"
          ref="phoneNumber"
        >
        <input-tel-brandme 
          v-if="user_id"
          :country_dad="country_selected" 
          :number_dad="telephone"
          @number="updateTelephone"
        ></input-tel-brandme>
      </validation-provider>
      </b-col>
      <!-- Location -->

      <b-col class="col-12 mt-3" :key="ready_request">
        <div class="d-flex align-items-center">
          <h3>{{ $t('spokenLanguage') }}</h3>
          <b-badge variant="color-badge" pill class="color-badge ml-1">{{ $t('maxLanguage') }}</b-badge>
        </div>
        <b-row class="mr-1 ml-0">
          <b-button
            v-for="(language, index) in $t('creator.languages')"
            :key="index"
            :variant="isLanguageSelected(language)[0]"
            :class="isLanguageSelected(language)[0]"
            pill
            @click="addOrRemoveLanguage(language)">
            <b-icon :icon="isLanguageSelected(language)[1]"></b-icon>
            {{ language.text}}
          </b-button>
        </b-row>
        <div v-if="languages_selected.length > 4" class="alert alert-warning msj-warning" role="alert">
          <div>
          <feather-icon icon="InfoIcon" class="mr-1" size="20"/> <span>{{ $t('alertLanguage') }}</span>
          </div> 
        </div>
      </b-col>

      <b-col class="col-12 d-flex justify-content-center">
        <b-button variant="blue-button-next-step" :disabled="!state_telephone || invalid" class="blue-button-next-step my-2" @click="save()">{{ getTextButton() }}</b-button>
      </b-col>
    </b-form>
    </validation-observer>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BBadge,
  BIcon,
  BForm,
} from 'bootstrap-vue';
import { getMotivationalPhrase } from '@/libs/utils/others'
import { setPermissions, setuserData } from '@/libs/utils/user'
import others from '@/services/others'
import abilities from '@/libs/acl/abilities';
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, length } from '@validations';
import InputTelBrandme from '../inputs/InputTelBrandme.vue';

export default {
  name: 'PublicProfileBasicInfo',
  components: {
    BRow,
    BCol,
    BButton,
    BBadge,
    BIcon,
    BForm,
    ValidationProvider,
    ValidationObserver,
    InputTelBrandme,
  },
  data() {
    return {
      setuserData,
      setPermissions,
      getMotivationalPhrase,
      required,
      length,
      country_selected: '',
      options_countries: [],
      languages_selected: [],
      ready_request: false,
      telephone: '',
      has_languages: false,
      interval_loading: null,
      editing: this.$route.params.is_edit ? this.$route.params.is_edit : '',
      state_telephone: false,
      user_id: null,
    }
  },
  created() {
    localize(this.$i18n.locale)
    this.getProfile()
  },
  methods: {
    getProfile () {
      others.getProfile().then(response => {
        const user = response.user;
        this.user_id = user.id;
        this.telephone = user.telephone ? user.telephone : ''
        this.country_selected = user.country ? user.country : 'MX'
        if (user.meta_info && user.meta_info.languages && user.meta_info.languages.length > 0) {
          this.has_languages = true
          user.meta_info.languages.forEach(lan => {
            const language_exists = this.$t('creator.languages').find(language => language.value === lan)
            if (language_exists) {
              this.languages_selected.push(language_exists)
            }
          })
        }

        this.ready_request = !this.ready_request
      })
    },
    getTextButton() {
      if (this.editing) return this.$t('dashboard.save')
      else return this.$t('creator.butonNext')
    },
    isLanguageSelected(language) {
      const exists_language = this.languages_selected.find(lan => language.value === lan.value)
      return exists_language !== undefined ? ['class-btn-check', 'check2'] : ['class-btn-plus', 'plus'];
    },
    addOrRemoveLanguage(language) {
      if (this.languages_selected.find((lan) => language.value === lan.value) !== undefined) {
        this.languages_selected = this.languages_selected.filter((lan) => lan !== language);
      } else if (this.languages_selected.length < 5) {
        this.languages_selected.push(language)
      }
    },
    updateTelephone(telephone_data) {
      this.telephone = telephone_data.number;
      this.state_telephone = telephone_data.state;
      this.country_selected = telephone_data.country;
    },
    save() {
      const format_languages = []
      this.languages_selected.forEach(lan => {
        format_languages.push(lan.value)
      });

      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: getMotivationalPhrase(),
          });
        }
      }, 5000);

      const data = {
        country: this.country_selected,
        languages: format_languages,
        telephone: this.telephone,
        empty_languages: format_languages.length === 0
      }

      others.updateProfile(data).then(response => {
        setPermissions(response.user);
        this.readAbility = abilities.getAbilitie(response.user) 
        
        setuserData(response, this.readAbility)

        if (this.editing) {
          this.$router.push({name: 'my_work', params: {section: 'networks'}});
          this.$root.$emit('quit_navbar_white');
        } else {
          this.$router.push({ name: 'setting-public-profile', params: {section: 'demographics'}})
        }

        clearInterval(this.interval_loading)
        this.$vs.loading.close()
      })
        .catch(() => {
          clearInterval(this.interval_loading)
          this.$vs.loading.close()
        })
    },
  },
}
</script>
<style>
.class-btn-plus {
  margin: 7px 4px 0px 0px;
  padding: 5px 13px 5px 13px;
  background-color: #fff !important;
  border-color: #ced4da !important;
  color: #6c757d !important;
}
.class-btn-check {
  margin: 7px 4px 0px 0px;
  padding: 5px 13px 5px 13px;
  background-color: #d5ebe4 !important;
  border-color: #d5ebe4 !important;
  color: #267e5e !important;
}
.msj-warning {
  margin-top: 10px;
  padding: 10px;
  font-size: small;
}
.color-badge {
  background: #FCD147;
  color: black;
  font-size: 75%;
}
.country-selector__list__item {
  display: block;
  padding: 2em 1em;
}
</style>