<template>
  <layout-stepper :key="current_section">
    <template #body>
      <b-link @click="makeRedirect()" v-if="has_prev_step" class="link-back">
        <feather-icon icon="ChevronLeftIcon"></feather-icon>
        {{ $t('creator.previousStep') }}
      </b-link>

      <connections v-if="current_section === 'connections'"/>
      <profile v-if="current_section === 'profile'"/>
      <basic-info v-if="current_section === 'basic_info'"/>
      <demographics v-if="current_section === 'demographics'"/>
      <family v-if="current_section === 'family'"/>
      <vanity v-if="current_section === 'vanity'"/>
    </template>
  </layout-stepper>
</template>

<script>
import {
  BLink
} from 'bootstrap-vue';

import layoutStepper from '@/views/components/proposals/layoutStepper.vue';
import connections from '../setting-public-profile/connections.vue'
import profile from '../setting-public-profile/profile.vue'
import basicInfo from './basicInfo.vue';
import demographics from './demographics.vue';
import family from './family.vue';
import vanity from './vanity.vue';

export default {
  name: 'settingPublicProfileDahsboard',
  components: {
    BLink,
    layoutStepper,
    connections,
    profile,
    basicInfo,
    demographics,
    family,
    vanity
  },
  data() {
    return {
      has_prev_step: false,
      current_section: this.$route.params.section,
    }
  },
  created() {
    if (this.current_section !== 'connections') this.has_prev_step = true
  },
  destroyed() {
    if (this.current_section === 'vanity') this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false);
  },
  methods: {
    makeRedirect() {
      if (this.current_section === 'profile') this.$router.push({ name: 'setting-public-profile', params: {section: 'connections'}})
      else if (this.current_section === 'basic_info') this.$router.push({ name: 'setting-public-profile', params: {section: 'profile'}})
      else if (this.current_section === 'demographics') this.$router.push({ name: 'setting-public-profile', params: {section: 'basic_info'}})
      else if (this.current_section === 'family') this.$router.push({ name: 'setting-public-profile', params: {section: 'demographics'}})
      else if (this.current_section === 'vanity') this.$router.push({ name: 'setting-public-profile', params: {section: 'family'}})
    }
  },
  watch: {
    '$route.params.section'(new_val) {
      this.current_section = new_val
      if (new_val !== 'connections') this.has_prev_step = true
      else this.has_prev_step = false
    }
  }
}
</script>
